.logo-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo {
	border-radius: 50%;
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 768px) {
	.logo-container {
		width: 50px;
		height: 50px;
	}
	
}
