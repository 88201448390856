@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 380px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: var(--background-navbar-color);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
	backdrop-filter: blur(10px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.nav-list {
	display: flex;
	justify-content: space-around;
	list-style: none;
	align-items: center;
	height: 20px;
	margin-block-start: 0px;
	margin-block-end: 0px;
	padding-inline-start: 0px;
	width: 100%;
	padding-inline: 10px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.nav-item-icon {
	cursor: pointer;
	font-weight: bold;
	font-size: 85%;
	color: var(--primary-color);
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 85%;
		font-size: 85%;
		margin-top: 10px;
		margin-right: 20px;
	}

	.nav-background {
		width: 85%;
		height: 40px;
	}

	.nav-item {
		font-weight: bold;
	}
}
