@import "../../../data/styles.css";

.hobbies-body {
	margin-bottom: 10px;
}

.hobby {
	position: relative;
	display: flex;
	padding-bottom: 40px;
}

.hobby-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid var(--border-color);
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
	background-color: color-mix(in srgb, var(--quaternary-color) 10%, var(--quaternary-color) 7%);
}

.hobby-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.hobby-description {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
	overflow-wrap: break-word;
}
